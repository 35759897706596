<template>
    <vx-card title="Work Order - Execution">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="tabOrder">
                <vs-tab label="Open" :disabled="true">
                    <tab-open></tab-open>
                </vs-tab>
                <vs-tab label="Picking" :disabled="true">
                    <tab-picking></tab-picking>
                </vs-tab>
                <vs-tab label="Execute">
                    <tab-execute></tab-execute>
                </vs-tab>
                <!-- <vs-tab label="Putaway">
                    <tab-putaway></tab-putaway>
                </vs-tab> -->
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
                <vs-tab label="Cancel Execute">
                    <tab-cancel-execute></tab-cancel-execute>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabOpen from "./TabOpen.vue";
import TabPicking from "./TabPicking.vue";
import TabExecute from "./TabExecute.vue";
// import TabPutaway from "./TabPutaway.vue";
import TabComplete from "./TabComplete.vue";
import TabCancelExecute from "./TabCancelExecute.vue";

export default {
    components: {
        TabOpen,
        TabPicking,
        TabExecute,
        // TabPutaway,
        TabComplete,
        TabCancelExecute
    },
    data() {
        return {
            activeTab: "Open",
            render: true,
            tabOrder: 2, // ini nanti jadikan dinamis
        }
    },
    methods: {}
}
</script>