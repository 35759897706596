<template>
    <v-select :multiple="multiple" :filterable="false" :options="options" v-model="selected" :placeholder="placeholder"
        :item-text="'text'" :item-value="'value'" />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
    },
    data() {
        return {
            selected: null,
            options: [
                {
                    label: "SKU ID Change",
                    value: 0
                },
                {
                    label: "Repackaging",
                    value: 1
                }
            ]
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
    }
}
</script>